import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { labAnalyzerSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { lab_analyzer_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";
import MasterServices from "../../../ApiServices/MasterServices";
import Multiselect from "multiselect-react-dropdown";
import queryString from "query-string";
import { toast } from "react-toast";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import CustomButton from "../../../components/common/CustomButton";
import ExcelServices from "../../../ApiServices/ExcelServices";

const API = process.env.REACT_APP_API_URL;

const analyzerTypeList = [
  {
    value: 1,
    label: "Type 1",
  },
  {
    value: 2,
    label: "Type 2",
  },
];

function LabAnalyzer() {
  const pages = [
    { title: "LabAnalyzer List", href: "/lab_analyzer", module_id: 26 },
  ];
  const animatedComponents = makeAnimated();
  const [labAnalyzerList, setLabAnalyzerList] = useState(false);
  const [testList, setTestList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [originalTestList, setOriginalTestList] = useState([]);
  const [originalItemList, setOriginalItemList] = useState([]);
  const [originalLocList, setOriginalLocList] = useState([]);
  const [tItemList, setTItemList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [lab_analyzer_id, setLabAnalyzerId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState({
    value: "",
    label: "",
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    lab_analyzer_id: null,
  });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Labib | Lab Analyzers";
    getAllTests();
    getAllLabAnalyzers();
    getAllTItemList();
    getAllLocationList();
  }, []);

  useEffect(() => {
    console.log("test List", testList);
  }, [testList]);

  const downloadSample = async () => {
    try {
      const response = await ExcelServices.getLabAnalyzerSampleExcel();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LabAnalyzerSampleTemplate.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const exportLabAnalyzer = async () => {
    try {
      const response = await ExcelServices.downloadLabAnalyzerExcel();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "LabAnalyzerExport.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };







  const onSelect = (selectedList, type) => {
    if (type == "fk_test_ids") {
      values.fk_test_ids = selectedList.map((v) => v.id);
      setSelected(selectedList);
    } else if (type == "fk_titem_ids") {
      values.fk_titem_ids = selectedList.map((v) => v.id);
      setSelectedItems(selectedList);
    } else if (type == "fk_loc_ids") {
      values.fk_loc_ids = selectedList.map((v) => v.id);
      setSelectedLocations(selectedList);
    }
  };

  const onRemove = (selectedList, type) => {
    if (type == "fk_test_ids") {
      values.fk_test_ids = selectedList.map((v) => v.id);
      setSelected(selectedList);
    } else if (type == "fk_titem_ids") {
      values.fk_titem_ids = selectedList.map((v) => v.id);
      setSelectedItems(selectedList);
    } else if (type == "fk_loc_ids") {
      values.fk_loc_ids = selectedList.map((v) => v.id);
      setSelectedLocations(selectedList);
    }
  };

  const removeDuplicateObjects = (array1, array2, key) => {
    let a = array2.filter((item) => {
      for (let i = 0; i < array1.length; i++) {
        console.log(array1[i].id == item.id);
        if (array1[i].id == item.id) {
          return false;
        }
      }
      return true;
    });

    if (a.length === 0) {
      return array2;
    } else {
      return a;
    }
  };

  // To Modify data for multi select
  function transformData(list, type) {
    console.log(type);
    if (type == "test_id") {
      return list.map((item) => ({
        name: item.test_name_en,
        id: item.test_id ? item.test_id : item,
      }));
    } else if (type == "ti_id") {
      return list.map((item) => ({
        name: item.ti_barcode_id,
        id: item.ti_id ? item.ti_id : item,
      }));
    } else if (type == "loc_id") {
      return list.map((item) => ({
        name: item.loc_name_en,
        id: item.loc_id ? item.loc_id : item,
      }));
    }
  }

  const getAllTests = async () => {
    try {
      setIsLoading(true);

      const { data } = await MasterServices.getTestList("active");
      console.log("All Test Data", data);
      if (data.success) {
       const fitlterData = data.data.map((v) => ({
          ...v,
          name: v.test_name_en,
          id: v.test_id
        }))
        setTestList(fitlterData);
        setOriginalTestList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllLabAnalyzers = async () => {
    try {
      setIsLoading(true);
      const { data } = await Api().get(`/api/dashboard/tests/lab-analyzer`);
      console.log("All Lab Analyzers Data", data);
      if (data.status) {
        setLabAnalyzerList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getAllTItemList = async () => {
    try {
      setIsLoading(true);
      const { data } = await MasterServices.getTItemsList();
      console.log("All Lab Analyzers Data", data);
      if (data.status) {
        setTItemList(data.data);
        setOriginalItemList(data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const deleteAnalyzer = async (payload) => {
    try {
      setIsLoading(true);
      const { data } = await Api().post(
        `/api/dashboard/tests/lab-analyzer/delete`,
        payload
      );
      if (data.status) {
        getAllLabAnalyzers();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const addLabAnalyzer = async () => {
    try {
      setIsLoading(true);
      console.log("Add Lab Analyzer");
      let body = {
        lab_analyzer_name_en: values.lab_analyzer_name_en,
        lab_analyzer_name_ar: values.lab_analyzer_name_ar,
        lab_analyzer_type: values.lab_analyzer_type,
        fk_test_ids: values.fk_test_ids,
        lab_analyzer_id: lab_analyzer_id,
        fk_titem_ids: values.fk_titem_ids,
        fk_loc_ids: values.fk_loc_ids,
      };
      if (!lab_analyzer_id) {
        delete body.lab_analyzer_id;
      }
      const res = await Api().post(`/api/dashboard/tests/lab-analyzer`, body);
      if (res.status) {
        toast.success(res?.data?.message);
        getAllLabAnalyzers();
      }
      console.log("Add Lab Analyzer Response", res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getAllLocationList = () => {
    let payload = {
      search: 1,
    };
    MasterServices.getAllLocationList(queryString.stringify(payload))
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("data.data", data.data);
          
          if (data?.data?.length > 0) {
            setLocationList(data.data);
            setOriginalLocList(data.data);
          } else {
            // toast.error("Location List Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllLocationTestList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  };
  const initialValues = {
    lab_analyzer_id: "",
    lab_analyzer_name_en: "",
    lab_analyzer_name_ar: "",
    lab_analyzer_type: "",
    fk_test_ids: [],
    fk_titem_ids: [],
    fk_loc_ids: [],
  };

  const [formLabAnalyzer, setFormLabAnalyzer] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setLabAnalyzerId(id);
      let qctestlistarr = transformData(
        originalTestList.filter((v) => obj.fk_test_ids.includes(v.test_id)),
        "test_id"
      );
      let itemlistarr = transformData(
        originalItemList.filter((v) => obj.fk_titem_ids.includes(v.ti_id)),
        "ti_id"
      );
      let loclistarr = transformData(
        originalLocList.filter((v) => obj.fk_loc_ids.includes(v.loc_id)),
        "loc_id"
      );
      setSelected(qctestlistarr);
      setSelectedItems(itemlistarr);
      setSelectedLocations(loclistarr);
      let newTests = transformData(originalTestList, "test_id");
      let newITems = transformData(originalItemList, "ti_id");
      let newLocations = transformData(originalLocList, "loc_id");

      console.log(qctestlistarr, newTests);
      console.log(itemlistarr, newITems);
      console.log(loclistarr, newLocations);
      newTests = removeDuplicateObjects(qctestlistarr, newTests);
      newITems = removeDuplicateObjects(itemlistarr, newITems);
      newLocations = removeDuplicateObjects(loclistarr, newLocations);
      console.log(newTests);
      setTestList(newTests);
      setTItemList(newITems);
      setLocationList(newLocations);
      console.log("newLocations", newLocations);
      const initialValues = {
        lab_analyzer_id: obj.lab_analyzer_id,
        lab_analyzer_name_en: obj.lab_analyzer_name_en,
        lab_analyzer_name_ar: obj.lab_analyzer_name_ar,
        fk_test_ids: obj.fk_test_ids,
        lab_analyzer_type: obj.lab_analyzer_type,
        fk_titem_ids: obj.fk_titem_ids,
        fk_loc_ids: obj.fk_loc_ids,
      };
      // console.log(setSelectedLocationId, "setSelectedLocationId")
      // if(Array.isArray(locationList) && locationList.length > 0) {
      // 	let role = locationList.filter(v => v.loc_id == initialValues.fk_loc_id)
      // 	if(role.length > 0){
      // 		setSelectedLocationId({ value: role[0].loc_id, label: role[0].loc_name_en })
      // 	}
      // }
      setFormLabAnalyzer(initialValues);
    } else {
      // setSelectedLocationId('')
      setSelected([]);
      setSelectedItems([]);
      let newTests = transformData(originalTestList, "test_id");
      let newITems = transformData(originalItemList, "ti_id");
      let newLocations = transformData(originalLocList, "loc_id");
      setTestList(newTests);
      setTItemList(newITems);
      setLocationList(newLocations);
      setLabAnalyzerId("");
      setFormLabAnalyzer(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };
  const onDeleteOpen = (lab_analyzer_id) => {
    setConfirmationModal({ lab_analyzer_id, status: true });
  };

  const onDeleteLabAnalyzer = (lab_analyzer_id) => {
    setConfirmationModal({ lab_analyzer_id: null, status: false });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formLabAnalyzer,
      validationSchema: labAnalyzerSchema,
      onSubmit: (values, action) => {
        console.log("Submit Clicked", values);
        addLabAnalyzer();
        // console.log("Update LabAnalyzer")
        action.resetForm();
        if (modalOpenFlage === true) {
          // getAllLabAnalyzers()
          setmodalOpenFlage(false);
        }
        navigate("/lab-analyzer");
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => {
          onDeleteLabAnalyzer(confirmationModal.lab_analyzer_id);
          deleteAnalyzer({
            lab_analyzer_id: confirmationModal.lab_analyzer_id,
          });
        }}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Lab Analyzer</h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the Lab Analyzers.
        </p>
      </div>
      <div className="mt-4 flex items-center justify-end gap-3">
        {/* <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add Lab Analyzer
        </Link> */}
        <CustomButton onClick={() => handleDrawer("add", "", {})}>
          Add Lab Analyzer
        </CustomButton>

        <CustomButton onClick={() => exportLabAnalyzer()}>Export</CustomButton>
        <CustomButton onClick={() => { }}>Import</CustomButton>
        <CustomButton onClick={() => downloadSample()}>
          Download Sample
        </CustomButton>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={lab_analyzer_columns({ onDeleteOpen, handleDrawer })}
          data={
            labAnalyzerList?.length > 0 && !isLoading ? labAnalyzerList : []
          }
        />
      )}

      {isLoading && testList ? null : (
        <div>
          <Transition.Root show={modalOpenFlage} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-30"
              onClose={setmodalOpenFlage}
            >
              <div className="fixed inset-0" />
              <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                  <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                    <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-500 sm:duration-700"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-500 sm:duration-700"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                    >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                          }}
                          className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                        >
                          <div className="h-0 flex-1 overflow-y-auto">
                            <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                              <div className="flex items-center justify-between">
                                <Dialog.Title className="text-lg font-medium text-white">
                                  {lab_analyzer_id ? "Update" : "Add"} Lab
                                  Analyzer
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                  <button
                                    type="button"
                                    className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                    onClick={() => setmodalOpenFlage(false)}
                                  >
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-1 flex-col justify-between">
                              <div className="p-4 sm:p-6">
                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Lab Analyzer Name English
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.lab_analyzer_name_en}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Lab Analyzer Name En"
                                      name="lab_analyzer_name_en"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.lab_analyzer_name_en &&
                                      touched.lab_analyzer_name_en ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.lab_analyzer_name_en}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Lab Analyzer Name Arabic
                                    </label>
                                  </div>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={values.lab_analyzer_name_ar}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter Lab Analyzer Name Ar"
                                      name="lab_analyzer_name_ar"
                                      autoComplete="off"
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />
                                    {errors.lab_analyzer_name_ar &&
                                      touched.lab_analyzer_name_ar ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.lab_analyzer_name_ar}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_name_en"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Select Test
                                    </label>
                                  </div>
                                  <div className="text-left mt-4 z-[999] h-max">
                                    <Multiselect
                                      dataKey="id"
                                      selectedValues={selected}
                                      onSelect={(e) =>
                                        onSelect(e, "fk_test_ids")
                                      }
                                      onRemove={(e) =>
                                        onRemove(e, "fk_test_ids")
                                      }
                                      displayValue="name"
                                      options={testList}
                                    />
                                  </div>
                                  {errors.fk_test_ids && touched.fk_test_ids ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_test_ids}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="lab_analyzer_type"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Type
                                    </label>
                                  </div>
                                  <select
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                    name="lab_analyzer_type"
                                    id="lab_analyzer_type"
                                    value={values.lab_analyzer_type}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option value={null}> Select Type </option>
                                    {analyzerTypeList?.map((type, i) => (
                                      <option
                                        defaultValue={
                                          lab_analyzer_id
                                            ? type.label ===
                                              values.lab_analyzer_type
                                              ? type.value
                                              : ""
                                            : ""
                                        }
                                        key={type.state_id}
                                        value={type.state_id}
                                      >
                                        {type.label}
                                      </option>
                                    ))}
                                  </select>

                                  {errors.lab_analyzer_type &&
                                    touched.lab_analyzer_type ? (
                                    <div className="text-sm text-red-600">
                                      {errors.lab_analyzer_type}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="fk_titem_id"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Select Items
                                    </label>
                                  </div>
                                  <div className="text-left   mt-4 z-[999] h-max">
                                    <Multiselect
                                      dataKey="ti_id"
                                      selectedValues={selectedItems}
                                      onSelect={(e) =>
                                        onSelect(e, "fk_titem_ids")
                                      }
                                      onRemove={(e) =>
                                        onRemove(e, "fk_titem_ids")
                                      }
                                      displayValue="name"
                                      options={tItemList}
                                    />
                                  </div>
                                  {errors.fk_titem_ids &&
                                    touched.fk_titem_ids ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_titem_ids}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                  <div>
                                    <label
                                      htmlFor="fk_loc_id"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Location
                                    </label>
                                  </div>
                                  {locationList && locationList.length > 0 ? (
                                    <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                      {/* <select
                                                                                    
                                                                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                                    name="fk_loc_id"
                                                                                    id="fk_loc_id"
                                                                                    value={values.fk_loc_id}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                >
                                                                                    <option> Select Location </option>
                                                                                    {locationList.map((location, i) => (
                                                                                        <option
                                                                                            selected={
                                                                                                location.loc_id ===
                                                                                                    values.fk_loc_id
                                                                                                    ? "selected"
                                                                                                    : ""
                                                                                            }
                                                                                            key={i}
                                                                                            value={location.loc_id}
                                                                                        >
                                                                                            {location.loc_name_en}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>

                                                                                {errors.fk_loc_id &&
                                                                                    touched.fk_loc_id ? (
                                                                                    <div className="text-sm text-red-600">
                                                                                        {errors.fk_loc_id}
                                                                                    </div>
                                                                                ) : null} */}
                                      {/* <Select
																				name="loc_name_en"
																				id="loc_id"
																				placeholder="Select Locations"
																				value={selectedLocationId}
																				onChange={(e) => {
																					values.fk_loc_id = e.value;
																					setSelectedLocationId(e)
																				}}
																				// onBlur={handleBlur}
																				components={animatedComponents}
																				options={locationList ? locationList.map(v => ({value: v.loc_id, label: v.loc_name_en})) : []}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/> */}

                                      <Multiselect
                                        dataKey="loc_id"
                                        selectedValues={selectedLocations}
                                        onSelect={(e) =>
                                          onSelect(e, "fk_loc_ids")
                                        }
                                        onRemove={(e) =>
                                          onRemove(e, "fk_loc_ids")
                                        }
                                        displayValue="name"
                                        options={locationList}
                                      />

                                      {errors.fk_loc_id && touched.fk_loc_id ? (
                                        <div className="text-sm text-red-600">
                                          {errors.fk_loc_id}
                                        </div>
                                      ) : null}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-shrink-0 justify-end px-4 py-4">
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              {lab_analyzer_id ? "Update" : "Add"}
                            </button>
                          </div>
                        </form>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
}
export default LabAnalyzer;
