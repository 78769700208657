/* eslint-disable import/no-anonymous-default-export */
import Api from './Api';

export default {
	// User/Paitent Section Start

	async getAllPatientsLogs(payload) {
		const response = await Api().post(`api/dashboard/masters/up_user/patient/logs`, payload);
		return response;
	},
	async saveUserPatient(payload) {
		const response = await Api().post(`api/dashboard/masters/up_user/save`, payload);
		return response;
	},

	async saveUserPatientOC(payload) {
		const response = await Api().post(`api/dashboard/masters/up_user/save-oc-patient`, payload);
		return response;
	},

	async getAllUserList(payload) {
		console.log("payload", payload);

		const response = await Api().get(`api/dashboard/masters/up_user?page_no=${payload ? payload?.page_no : 1}&page_size=${payload ? payload?.page_size : 1000}`);
		return response;
	},

	async getSingleUser(payload) {
		const response = await Api().get(`api/dashboard/masters/up_user/${payload}`);
		return response;
	},

	async setUserStatus(payload) {
		const response = await Api().post(`api/dashboard/masters/up_user/status`, payload);
		return response;
	},

	async getAllPatientList(payload) {
		const response = await Api().get(`api/dashboard/masters/up_user/patient/all`);
		return response;
	},

	// i have do that someone are change this
	// async getSingleUserBasedPatient(payload) {
	// 	const response = await Api().get(`api/dashboard/masters/up_user/patient/user/${payload}`);
	// 	return response;
	// },

	async getSingleUserBasedPatient(payload, meta) {
		const response = await Api().get(`api/dashboard/masters/up_user/patient/user/${payload}?page_no=${meta?.page}&page_size=${meta?.pageSize}&search_text=${meta?.search_text}`);
		return response;
	},

	async getSinglePatient(payload) {
		const response = await Api().get(`api/dashboard/masters/up_user/patient/${payload}`);
		return response;
	},

	async setPatientStatus(payload) {
		const response = await Api().post(`api/dashboard/masters/up_user/patient/status`, payload);
		return response;
	},

	async getAllTestPackage(payload) {
		const response = await Api().get(`api/dashboard/masters/package/filter/test_packages`);
		return response;
	},

	async saveReservation(payload) {
		const response = await Api().post(`api/dashboard/reservation/save`, payload);
		return response;
	},

	async getAllReservationListBak() {
		const response = await Api().get(`api/dashboard/reservation`);
		return response;
	},

	async getAllReservationList(payload) {
		const response = await Api().get(`api/dashboard/reservation/${payload.page}/${payload.pageSize}`);
		return response;
	},

	async getAllRefundedReservationList(payload) {
		const response = await Api().get(`api/dashboard/reservation/refunded/${payload.page}/${payload.pageSize}`);
		return response;
	},

	async getAllInvoicesReservationList(payload) {
		const response = await Api().get(`api/dashboard/reservation/invoices/${payload.page}/${payload.pageSize}`);
		return response;
	},

	async getAllRejectedReservationList(payload) {
		const response = await Api().get(`api/dashboard/reservation/rejected/${payload.page}/${payload.pageSize}`);
		return response;
	},

	async getAllOutSourceReservationList() {
		const response = await Api().get(`api/dashboard/reservation/get-all-reservation-outsource`);
		return response;
	},

	async getOutsourceClinicPatientList(clinic_id) {
		const response = await Api().get(`api/dashboard/masters/up_user/oc-patients/${clinic_id ? clinic_id : ''}`);
		return response;
	},

	async getOutSourceReservationFilteredList(payload) {
		const response = await Api().post(`api/dashboard/reservation/get-all-reservation-outsource-filtered`, payload);
		return response;
	},
	async getOutSourcePaitentReport(payload) {
		const response = await Api().post(`api/dashboard/reports/oc-paitent-report`, payload);
		return response;
	},
	async getOutSourceTestReport(payload) {
		const response = await Api().post(`api/dashboard/reports/oc-test-report`, payload);
		return response;
	},



	async getAllReservationFilterList(payload) {
		console.log("getAllReservationFilterList payload=>", payload)
		const response = await Api().get(`api/dashboard/reservation/filter/all/?${payload}`);
		return response;
	},

	async generateInvoiceReport(payload) {
		const response = await Api().post(`api/dashboard/reservation/generate_invoice`, payload);
		return response;
	},

	async generateCreditInvoiceReport(payload) {
		const response = await Api().post(`api/dashboard/reservation/generate_credit_invoice`, payload);
		return response;
	},

	async getSingleReservation(payload) {
		const response = await Api().get(`api/dashboard/reservation/${payload}`);
		return response;
	},

	async setReservationPostponeStatus(payload) {
		const response = await Api().post(`api/dashboard/reservation/reservation-postpone`, payload);
		return response;
	},

	async setReservationCancelStatus(payload) {
		const response = await Api().post(`api/dashboard/reservation/reservation-cancel`, payload);
		return response;
	},

	async getAllReservationAttachmentList(payload) {
		const response = await Api().get(`api/dashboard/reservation/reservation-attachment/${payload}`);
		return response;
	},

	async setReservationStatus(payload) {
		const response = await Api().post(`api/dashboard/reservation/status`, payload);
		return response;
	},
	async updateReservationCommment(payload) {
		const response = await Api().post(`api/dashboard/reservation/comment`, payload);
		return response;
	},
	async addReservationPayment(payload) {
		const response = await Api().post(`api/dashboard/reservation/payment`, payload);
		return response;
	},
	async addReservationPaymentCredit(payload) {
		const response = await Api().post(`api/dashboard/reservation/credit-payment`, payload);
		return response;
	},
	async updateReservationDiscount(payload) {
		const response = await Api().post(`api/dashboard/reservation/discount`, payload);
		return response;
	},

	async updateRefund(payload) {
		const response = await Api().post(`api/dashboard/reservation/reservation-refunded`, payload);
		return response;
	},
	async updateCancell(payload) {
		const response = await Api().post(`api/dashboard/reservation/reservation-cancel`, payload);
		return response;
	},

	async generateSample(payload) {
		const response = await Api().post(`api/dashboard/tests/sample/save`, payload);
		return response;
	},

	async viewSample(payload) {
		const response = await Api().get(`api/dashboard/tests/sample/list/${payload}`);
		return response;
	},

	async addAttachmentToResv(payload) {
		let progress = 0;
		console.log("payload=>", payload);
		let response = await Api().post(`api/dashboard/reservation/attach_file`, payload, {
			onUploadProgress: data => {
				progress = Math.round((100 * data.loaded) / data.total);
			},
		});
		//   let data = {
		// 	response,
		response.data.progress = progress;
		//   }
		console.log(response);
		return response;
	},
	async updateAttachmentToResv(payload) {
		const response = await Api().post(`api/dashboard/reservation/update-file`, payload);
		return response;
	},

	async getTestPackPatientDetails(payload) {
		let response = await Api().post(`api/dashboard/reservation/test-pack-patient`, payload);
		return response;
	},
	// User/Paitent Section Start

	async getUserAddressesByUserId(payload) {
		const response = await Api().get(`api/dashboard/user-address/user/${payload}`)
		return response
	},

	async getLatLong(payload) {
		const response = await Api().post(`api/dashboard/user-address/geocode/get-lat-long`, payload)
		return response
	},

	async createUserAddress(payload) {
		const response = await Api().post(`api/dashboard/user-address/`, payload)
		return response;
	},

	async updateUserAddress(payload, id) {
		const response = await Api().post(`api/dashboard/user-address/${id}`, payload)
		return response;
	},

	async updateUrgentStatus(payload) {
		const response = await Api().post(`api/dashboard/reservation/update-urgent-status`, payload)
		return response;
	},

	async updateSingleUrgentStatus(payload) {
		const response = await Api().post(`api/dashboard/reservation/update-single-urgent-status`, payload)
		return response;
	},

	// Sample Status Updating APIs
	async allSampleCollecitonByResId(reservation_id) {
		const response = await Api().post(`api/dashboard/tests/sample/status/collected/${reservation_id}`)
		return response;
	},

	//get reservation address for home
	async getSingleUserAddress(ua_id) {
		const response = await Api().get(`api/dashboard/user-address/${ua_id}`)
		return response;
	},

	async setLabTechnicianForReservation(payload) {
		const response = await Api().post(`api/dashboard/reservation/update-lab-technician`, payload);
		return response;
	},

	async cancelTestPack(payload) {
		const response = await Api().post(`api/dashboard/reservation/cancel-test-pack`, payload);
		return response;
	},

	async refundOne(payload) {
		const response = await Api().post(`api/dashboard/reservation/refund-one`, payload);
		return response;
	}

};
